import React, { useState, useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useFirebase } from '../contexts/FirebaseContext';

export default function VideoCard({title, description, id, thumbnailLink, userID}) {
  const [channelData, setChannelData] = useState();
  const { getDocument } = useFirebase();

  useEffect(() => {
    getDocument("users", userID).then((channel) => {
      setChannelData(channel.data());
    });
  }, []);

  return (
    <Link to={"/watch?id="+id} target="_blank" style={styles.link}>
    <Card style={styles.mainCard}>
        <Card.Img variant="top" src={thumbnailLink} style={styles.thumbnail}/>
        <Card.Body>
            <Card.Title style={styles.title}>{title}</Card.Title>
            <Card.Text style={styles.description}>
            {description}
            </Card.Text>
            <Link to={"/channel/?id="+userID+"&s=new"} target="_blank" style={{textDecoration: "none"}}>
              <Card.Text style={styles.user}>
                Uploaded by {channelData? channelData.username : "Anonymous"}
              </Card.Text>
            </Link>
        </Card.Body>
    </Card>
    </Link>
  )
}

const styles = {
  link: {
    textDecoration: "none",
  },
  mainCard: {
      width: "20vw",
      minHeight:"55vh",
      marginTop: "2vh"
  },
  thumbnail: {
    width: "20vw",
    height: "30vh",
    objectFit: "cover"
  },
  title: {
    color: "#000000"
  },
  description: {
    color: "#707071",
    height: "20px",
    overflow: "hidden"
  },
  user: {
    color: "#000000",
    overflow: "hidden"
  }
}