import React, { useState, useEffect, useRef } from 'react'
import { Card, Button, Alert, Container, Form } from "react-bootstrap"
import Header from "../header/Header.js";
import { useFirebase } from '../contexts/FirebaseContext.js';
import { Player } from 'video-react';
import { useSearchParams } from 'react-router-dom';
import Comment from './Comment.js';
import { v4 as uuidv4 } from 'uuid';

export default function Watch() {
  const commentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { queryVideoData, getDocument, queryCommentData, uploadCommentData } = useFirebase();
  const [videoData, setVideoData] = useState({});
  const [videoID, setVideoID] = useState("");
  const [commentsData, setCommentsData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();


  function handleSubmit(e) {
    e.preventDefault();
    console.log(commentRef.current.value);
    uploadCommentData(searchParams.get("id"), uuidv4(), commentRef.current.value)
    commentRef.current.value = "";
    setCommentsData([]);
    getComments(25, "timestamp", true);
  }

  async function getComments(n, orderByTerm, descending) {
    let q = await queryCommentData("videos/"+searchParams.get("id")+"/comments", orderByTerm, descending, n, 0);
    q.forEach((doc) => {
      

      setCommentsData(prevArray => [...prevArray, {
        comment: doc.data().comment,
        user: doc.data().user,
        username: doc.data().username,
        timestamp: doc.data().timestamp
      }])
      
      console.log(commentsData);
    })
  }

  function getCommentCards() {
    let commentCards = commentsData.map((item) => (
      <Comment 
        comment={item.comment} 
        username={item.username} 
        userID={item.user}
        timestamp={item.timestamp}
         />
    ));
    return commentCards;
  }

  useEffect(async () => {
      setVideoID(searchParams.get("id"));
      let q = await getDocument("videos", searchParams.get("id"));
      //console.log(q.data());
      setVideoData({
          title: q.data().title,
          description: q.data().description,
          likes: q.data().likes,
          dislikes: q.data().dislikes,
          views: q.data().views,
          uploadTime: q.data().uploadTime,
          url: q.data().videoLink
      });

      getComments(25, "timestamp", true);
  }, []);

  return (
  <>
    <Container  style={styles.mainContainer}>
      <Card style={styles.mainCard}>
        <Card.Body>
            <Card.Title>{videoData.title}</Card.Title>
            <Player
                playsInline
                poster="/assets/poster.png"
                src={videoData.url}
                />
            <Card.Text>{videoData.description}</Card.Text>
        </Card.Body>
    </Card>
    <Card style={styles.commentsCard}>
        <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control type="text" placeholder="Type a comment" ref={commentRef}></Form.Control>
              </Form.Group>
              <Button className="w-100 mt-3" type="submit" disabled={loading}>Comment</Button>
            </Form>
            <br ></br>
            <Card.Title>Comments</Card.Title>
            {getCommentCards()}
        </Card.Body>
    </Card>
    </Container>
    </>
  )
}

const styles = {
  mainContainer: {
    minHeight: "90vh",
    minWidth: "90vw",

    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  },
  mainCard: {
    width: "60vw",
    marginTop: "2vh"
  },
  commentsCard: {
    width: "25vw",
    height:"80vh",
    marginTop: "2vh",
    overflow: "scroll"
  }
}
