import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import { Card, Button, Alert, Container, Form, Navbar, Nav, NavDropdown, List, ListGroup } from "react-bootstrap"
import Header from "../header/Header.js";
import VideoCard from '../home/VideoCard.js';
import { useFirebase } from '../contexts/FirebaseContext.js';
import { ConstructionOutlined } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import ChannelHeader from '../header/ChannelHeader.js';

export default function ChannelInfo() {
  const [error, setError] = useState();
  const { queryVideoData, getDocument, currentUserName } = useFirebase();
  const [channelData, setChannelData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  async function getChannelData() {
    getDocument("users", searchParams.get("id")).then((channel) => {
        let created = channel.data().timestamp;
        let date = new Date(created)
        setChannelData({...channel.data(),
            created: date.toLocaleDateString("en-UK", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
         })})
    });
  }



  useEffect(() => {
    getChannelData();
  }, [])

  return (
  <>
    
    <Navbar bg="light" variant="light" expand="lg" style={styles.navbar}>
    <Container>
        <Navbar.Brand href={"/channel/?id="+searchParams.get("id")+"&s=new"}>{channelData ? channelData.username : ""}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href={"/channel/?id="+searchParams.get("id")+"&s=new"}>Videos</Nav.Link>
          <NavDropdown title="Newest" id="basic-nav-dropdown">
            <NavDropdown.Item href={"/channel/?id="+searchParams.get("id")+"&s=new"}>
                Newest
            </NavDropdown.Item>
            <NavDropdown.Item href={"/channel/?id="+searchParams.get("id")+"&s=old"}>
                Oldest
            </NavDropdown.Item>
            <NavDropdown.Item href={"/channel/?id="+searchParams.get("id")+"&s=top"}>
                Most viewed
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href={"/channel/info/?id="+searchParams.get("id")}>Channel info</Nav.Link>
        </Nav>
        </Navbar.Collapse>
    </Container>
    </Navbar>
    <Container  style={styles.mainContainer}>
    <div style={styles.infoContainer}>
        <Card>
          <Card.Body>
            <h2 className='text-center mb-3'>{channelData? channelData.username : "Channel"}</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <ListGroup>
              <ListGroup.Item><strong>About: </strong>{channelData? channelData.about : ""}</ListGroup.Item>
              <ListGroup.Item><strong>Created: </strong>{channelData? channelData.created : ""}</ListGroup.Item>
            </ListGroup>
            
          </Card.Body>
        </Card>
      </div>
    </Container>
    </>
  )
}

const styles = {
  mainContainer: {
    minHeight: "90vh",
    minWidth: "90vw",

    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  },
  infoContainer: {
      minWidth: "30vw",
      marginTop: "10vh"
  }
}