import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { Card, Button, Alert, Container, Form, Nav, Navbar, NavDropdown } from "react-bootstrap"
import Header from "../header/Header.js";
import VideoCard from './VideoCard.js';
import { useFirebase } from '../contexts/FirebaseContext.js';
import { ConstructionOutlined } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

export default function Dashboard() {
  const [error, setError] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryVideoData, getDocument } = useFirebase();
  const [allVideosLoaded, setAllVideosLoaded] = useState(false);
  //const [videosData, setVideosData] = useState([]);
  const [sortBy, setSortBy] = useState("Newest");
  const [videosData, setVideosData] = useState([]);
  const [lastVideo, setLastVideo] = useState();

  async function getVideosData(n, orderByTerm, descending) {
    let q = await queryVideoData("videos", orderByTerm, descending, n, 0);
    q.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.data().description)
      setVideosData(prevArray => [...prevArray, {
        title: doc.data().title,
        description: doc.data().description,
        id: doc.id,
        thumbnailLink: doc.data().thumbnailLink,
        //thumbnailLink: "https://firebasestorage.googleapis.com/v0/b/video-hosting-f9a9c.appspot.com/o/thumbnails%2F09bf8276-ccec-4aa0-bd5b-e32316bc5bf0.png?alt=media&token=2f5d5d2b-e1fe-4453-8349-8b4ce9909742"
        user: doc.data().user
      }])
    })
  }

  function getVideoCards() {
    let videoCards = videosData.map((item) => (
      <VideoCard 
        title={item.title} 
        description={item.description} 
        id={item.id} 
        key={item.id}
        thumbnailLink={item.thumbnailLink}
        userID={item.user}
      ></VideoCard>
    ));
    return videoCards;
  }

  useEffect(() => {
    let sb = searchParams.get("s");
    if (sb == "new") {
      setSortBy("Newest")
      getVideosData(100, "timestamp", true);
    } else if (sb == "old") {
      setSortBy("Oldest")
      getVideosData(100, "timestamp", false);
    } else if (sb == "top") {
      setSortBy("Most viewed")
      getVideosData(100, "views", true);
    } else {
      setSortBy("Newest")
      getVideosData(100, "timestamp", true);
    }
    
  }, [])


  return (
  <>
  <Navbar bg="light" variant="light" expand="lg" style={styles.navbar}>
    <Container>
        <Navbar.Brand>Videos</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
            <NavDropdown title={sortBy} id="basic-nav-dropdown">
            <NavDropdown.Item href={"/?s=new"}>
                Newest
            </NavDropdown.Item>
            <NavDropdown.Item href={"/?s=old"}>
                Oldest
            </NavDropdown.Item>
            <NavDropdown.Item href={"/?s=top"}>
                Most viewed
            </NavDropdown.Item>
            </NavDropdown>
        </Nav>
        </Navbar.Collapse>
    </Container>
    </Navbar>
    <Container  style={styles.mainContainer}>
      {getVideoCards()}
    </Container>
    </>
  )
}

const styles = {
  mainContainer: {
    minHeight: "90vh",
    minWidth: "90vw",

    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  }
}