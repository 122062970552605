import React, { useState } from 'react'
import { Card, Button, Alert, Container, ListGroup } from "react-bootstrap"
import Header from "../header/Header.js";
import { useAuth } from "../contexts/AuthContext.js";
import { useFirebase } from '../contexts/FirebaseContext.js';
import { Link, useNavigate } from "react-router-dom";
import { useDB } from '../contexts/DBContext.js';

export default function Profile() {
  const [error, setError] = useState();
  const { currentUser, logout, currentUserName } = useFirebase();
  const navigate = useNavigate();
  //const { currentUserName } = useDB();

  async function handleLogout() {
    setError("")
    try {
        await logout();
        window.location.reload();
        //navigate("/login")
    } catch(e) {
        console.log(e);
        setError("Couldn't log out");
    }
  }

  return (
  <>
    

    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "90vh" }}>

    <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className='text-center mb-3'>Profile</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <ListGroup>
              <ListGroup.Item><strong>Email: </strong> {currentUser.email}</ListGroup.Item>
              <ListGroup.Item><strong>Username: </strong> {currentUserName}</ListGroup.Item>
            </ListGroup>
            <Link to="edit" className="btn btn-primary w-100 mt-3">Edit profile</Link>
          </Card.Body>
        </Card>
        <div className='w-100 text-center mt-2'>
          <Button variant="link" onClick={handleLogout}>Log out</Button>
        </div>
      </div>
    </Container>
    </>
  )
}
