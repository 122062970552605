import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from '../contexts/AuthContext';
import { useFirebase } from '../contexts/FirebaseContext';
import { Link, useNavigate } from "react-router-dom";

export default function UpdateProfile() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { currentUser, changeEmail, changePassword } = useFirebase();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function handleSubmit(e) {
        
        e.preventDefault();
        
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords don't match")
        }

        const promises = []
        setLoading(true);
        setError("");
        if (emailRef.current.value != currentUser.email) {
            promises.push(changeEmail(emailRef.current.value));
        }
        if (passwordRef.current.value) {
            promises.push(changePassword(passwordRef.current.value));
        }

        Promise.all(promises).then(() => {
            navigate("/profile");
        }).catch((e) => {
            console.log(e);
            setError("Couldn't update profile");
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
    <>
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "90vh" }}>
        <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
            <Card.Body>
                <h2 className='text-center mb-3'>Edit profile</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email}></Form.Control>
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} required placeholder="Leave blank to keep the same password"></Form.Control>
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Password confirmation</Form.Label>
                        <Form.Control type="password" ref={passwordConfirmRef} required  placeholder="Leave blank to keep the same password"></Form.Control>
                    </Form.Group>
                    <Button className="w-100 mt-3" type="submit" disabled={loading}>Save</Button>
                </Form>
            </Card.Body>
        </Card>
        <div className='w-100 text-center mt-2'>
            <Link to="/profile">Cancel</Link>
        </div>
        </div>
        </Container>
    </>
  )
}
