import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAQm6oOkAlelosN1rbNhR_7DzYbKyCK_cs",
    authDomain: "video-hosting-f9a9c.firebaseapp.com",
    projectId: "video-hosting-f9a9c",
    storageBucket: "video-hosting-f9a9c.appspot.com",
    messagingSenderId: "70058637716",
    appId: "1:70058637716:web:d80bc93dacaaf09f483781",
    measurementId: "G-TE2Z7PTC1Q"
  };

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
