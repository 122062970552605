import React from 'react'
import { Route, Navigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext.js";
import { useFirebase } from './contexts/FirebaseContext.js';

export default function PrivateRoute({Component}) {
    const { currentUser } = useFirebase();
    let component = null;
    if (currentUser) {
        component = Component;
    } else {
        component = <Navigate to="/login"></Navigate>
    }
    return component
    
}
