import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
  } from "react-router-dom";
import Header from "./header/Header.js";
import Home from "./home/Home.js";
import Login from "./account/Login.js";
import Signup from './account/Signup.js';
import Profile from "./account/Profile.js"
import { Container } from "react-bootstrap";
import { AuthProvider, useAuth } from './contexts/AuthContext.js';
import { useFirebase, FirebaseProvider } from "./contexts/FirebaseContext.js";
import { DBProvider, useDB } from './contexts/DBContext.js';
import PrivateRoute from './PrivateRoute.js';
import ForgotPassword from './account/ForgotPassword.js';
import UpdateProfile from './account/UpdateProfile.js';
import UploadVideo from './upload/UploadVideo.js';
import Watch from './watch/Watch.js';
import Channel from './channel/Channel.js';
import ChannelInfo from "./channel/ChannelInfo.js"
import { height } from '@mui/system';



function App() {
    /*
    const { currentUser } = useAuth();

    const Private = (Component) => {
        //console.log(currentUser);
    
        return <Navigate to="/login" />
    }*/

    return (
        //<Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
            <Router>
                <FirebaseProvider>
                    
                    <Header style={styles.mainContainer}></Header>
                    
                      <Routes>
                          <Route exact path="/" element={<PrivateRoute Component={<Home/>}/>}></Route>
                          <Route path="/signup" element={<Signup/>}></Route>
                          <Route path="/login" element={<Login/>}></Route>
                          <Route path="/profile" element={<PrivateRoute Component={<Profile/>}/>}></Route>
                          <Route path="/forgot-password" element={<ForgotPassword/>}></Route>
                          <Route path="/profile/edit" element={<PrivateRoute Component={<UpdateProfile/>}/>}></Route>
                          <Route path="/upload" element={<PrivateRoute Component={<UploadVideo/>}/>}></Route>
                          <Route path="/watch" element={<PrivateRoute Component={<Watch/>}/>}></Route>
                          <Route path="/channel" element={<PrivateRoute Component={<Channel/>}/>}></Route>
                          <Route path="/channel/info" element={<PrivateRoute Component={<ChannelInfo/>}/>}></Route>
                        </Routes>
                          
                  </FirebaseProvider>
            </Router>
        //</Container>

      /*
      <div>
    <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route path="/dashboard" element={<Dashboard />}>
          </Route>
        </Routes>
    </Router>
    </div>
    */
  )
}


const styles = {
  mainContainer: {
    
  }
}

export default App;