import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useFirebase } from '../contexts/FirebaseContext';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

export default function UploadVideo() {
    const titleRef = useRef();
    const descriptionRef = useRef();
    const videoFileRef = useRef();
    const thumbnailFileRef = useRef();
    const { setDocument, uploadFile, getStorageURL, currentUser, updateDocument, uploadVideoData } = useFirebase();
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [selectedVideoFile, setSelectedVideoFile] = useState();
    const [selectedThumbnailFile, setSelectedThumbnailFile] = useState();
    const [thumbnailURL, setThumbnailURL] = useState("");
    const [videoURL, setVideoURL] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();
        setError("");
        setMessage("");
        setLoading(true);
        let id = uuidv4();
        console.log(currentUser.uid);
        if (selectedThumbnailFile.type == "image/png") {
            if (selectedVideoFile.type == "video/mp4") {
                handleThumbnail(id);
            } else {
                setError("Video must be an mp4")
            }
        } else {
            setError("Thumbnail must be a png")
        }
        
    }

    async function handleThumbnail(id) {
        console.log(id)
        uploadFile(selectedThumbnailFile, "thumbnails/"+id+".png").then(() => {
            console.log("uploaded thumbnail file")
            getStorageURL("thumbnails/"+id+".png").then((url) => {
                handleVideo(id, url);
                //console.log(url)
            })
        })
    }

    async function handleVideo(id, tnURL) {
        uploadFile(selectedVideoFile, "videos/"+id+".mp4").then(() => {
            console.log("uploaded video file")
            getStorageURL("videos/"+id+".mp4").then((url) => {
                console.log(url)
                uploadDataToDB(id, tnURL, url)
            })
        })
    }

    async function uploadDataToDB(id, tnURL, vidURL) {
        if (error == "") {
            try {
                console.log(tnURL, vidURL);
                
                uploadVideoData(id, titleRef.current.value, descriptionRef.current.value, vidURL, tnURL);
                
                
                //setLoading(false);
                //window.location.reload();
                setMessage("Successfully uploaded video");
                setLoading(false);
            } catch(e) {
                setError("Couldn't upload video");
                console.log(e)
                setLoading(false);
                //setLoading(false);
            }
        }
    }

    function handleThumbnailFileChange(e) {
        setSelectedThumbnailFile(e.target.files[0]);
    }

    function handleVideoFileChanged(e) {
        setSelectedVideoFile(e.target.files[0]);
    }

    return (
    <>
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "90vh" }}>
        <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
            <Card.Body>
                <h2 className='text-center mb-3'>{selectedVideoFile? selectedVideoFile.name: "Upload video"}</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" ref={titleRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" ref={descriptionRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id="thumbnail">
                        <Form.Label>Video file</Form.Label>
                        <Form.Control type="file" accept=".mp4" ref={videoFileRef} onChange={handleVideoFileChanged} required></Form.Control>
                    </Form.Group>
                    <Form.Group id="thumbnailLink">
                        <Form.Label>Thumbnail file</Form.Label>
                        <Form.Control type="file" accept=".png,.jpg,.jpeg" ref={thumbnailFileRef} onChange={handleThumbnailFileChange} required></Form.Control>
                    </Form.Group>
                    <Button className="w-100 mt-3" type="submit" disabled={loading}>Upload</Button>
                </Form>
            </Card.Body>
        </Card>
        <div className='w-100 text-center mt-2'>
            <Link to="/signup">Sign up</Link>
        </div>
        <div className='w-100 text-center mt-2'>
            <Link to="/forgot-password">Forgot password?</Link>
        </div>
        </div>
    </Container>
    </>
  )
}
