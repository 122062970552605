import React, { useContext, useState, useEffect } from 'react'
import { auth, db, storage } from "../utils/firebase.js";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword ,onAuthStateChanged, signOut, sendPasswordResetEmail, updateEmail, updatePassword } from 'firebase/auth';
import { doc, getDoc, setDoc, query, orderBy, limit, collection, getDocs, startAt, updateDoc } from 'firebase/firestore';
import { uploadBytes, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';

const FirebaseContext = React.createContext();

export function useFirebase () {
    return useContext(FirebaseContext);
}

export function FirebaseProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [currentUserName, setCurrentUserName] = useState();
    const [loading, setLoading] = useState(true);

    function signup(email, password, username) {
        createUserWithEmailAndPassword(auth, email, password).then((user) => {
            setDocument("users", user.user.uid, {
                username: username,
                email: email,
                timestamp: Date.now()
            })
        });
    }

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function logout() {
        return signOut(auth);
    }

    function resetPassword(email) {
        console.log("resetting")
        return sendPasswordResetEmail(auth, email);
    }

    function changeEmail(email) {
        return updateEmail(currentUser, email)
    }

    function changePassword(password) {
        return updatePassword(currentUser, password);
    }


    function setDocument(collection, docName, data) {
        return setDoc(doc(db, collection, docName), data);
    }

    function updateDocument(collection, docName, data) {
        //db.collection(collection).doc(docName).update(data);
        return updateDoc(doc(db, collection, docName), data);
    }

    function getDocument(collectionName, docName) {
        return getDoc(doc(db, collectionName, docName))
    }

    function queryVideoData(collectionName, orderByTerm, desc, limitNo, startingDoc) {
        if (desc) {
            let q = query(collection(db, collectionName), orderBy(orderByTerm, "desc"), limit(limitNo));
            return getDocs(q);
        } else {
            let q = query(collection(db, collectionName), orderBy(orderByTerm), limit(limitNo));
            return getDocs(q);
        }
    }

    function queryCommentData(collectionName, orderByTerm, desc, limitNo) {
        if (desc) {
            let q = query(collection(db, collectionName), orderBy(orderByTerm, "desc"), limit(limitNo));
            return getDocs(q);
        } else {
            let q = query(collection(db, collectionName), orderBy(orderByTerm), limit(limitNo));
            return getDocs(q);
        }
    }

    function uploadVideoData(id, title, description, vidURL, tnURL) {
        setDocument("videos", id, {
            title: title,
            description: description,
            videoLink: vidURL,
            thumbnailLink: tnURL,
            likes: 0,
            dislikes: 0,
            views: 0,
            timestamp: Date.now(),
            negativeTimestamp: -(Date.now()),
            user: currentUser.uid
        }).then(() => {
            setDoc(doc(db, "users/"+currentUser.uid+"/videos/"+id), {
                title: title,
                description: description,
                videoLink: vidURL,
                thumbnailLink: tnURL,
                likes: 0,
                dislikes: 0,
                views: 0,
                timestamp: Date.now(),
                negativeTimestamp: -(Date.now()),
                user: currentUser.uid
            })
            
        });
    }

    function uploadCommentData(vidID, id, text) {
        setDocument("videos/"+vidID+"/comments", id, {
            comment: text,
            timestamp: Date.now(),
            user: currentUser.uid,
            username: currentUserName
        })
    }

    async function uploadFile(file, path) {
        let storageRef = ref(storage, path);
        return uploadBytesResumable(storageRef, file)
    }
    
    function getStorageURL(path) {
        return getDownloadURL(ref(storage, path));
    }

        
    useEffect(() => {
        async function getCurrentUserName() {
            if (currentUser) {
                let docRef = doc(db, "users", currentUser.uid);
                let docSnap = await getDoc(docRef);
                //console.log(currentUser.email);
                //console.log(docSnap);
                setCurrentUserName(docSnap.data().username);
            }
        }
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            getCurrentUserName();
            setLoading(false);
        });


        //getCurrentUserName();

        return unsubscribe
    });
   

    const value = {
        currentUser,
        currentUserName,
        signup,
        login,
        logout,
        resetPassword,
        changeEmail,
        changePassword,
        setDocument,
        getDocument,
        queryVideoData,
        uploadFile,
        getStorageURL,
        updateDocument,
        uploadVideoData,
        queryCommentData,
        uploadCommentData
    }
    return (
    <FirebaseContext.Provider value={value}>
        {!loading && children}
    </FirebaseContext.Provider>
  )
}
