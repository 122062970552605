import React, { useRef } from 'react'
import { Navbar, NavDropdown, Nav, Container, Form, FormControl, Button } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useDB } from '../contexts/DBContext';
import { useFirebase } from "../contexts/FirebaseContext";

export default function Header() {
  const navigate = useNavigate();
  const searchRef = useRef()
  const { currentUser, currentUserName } = useFirebase();
  //const { currentUserName } = useDB();

  function handleSearch(e) {
    e.preventDefault();
    navigate("/search?q="+searchRef.current.value)
  }

  return (
    <Navbar bg="light" variant="light" expand="lg" style={styles.navbar}>
      <Container>
        <Navbar.Brand href="/?s=new">thing</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/?s=new">Home</Nav.Link>
            <NavDropdown title={currentUserName ? currentUserName : "Account"} id="basic-nav-dropdown">
              <NavDropdown.Item href="/login">Log in</NavDropdown.Item>
              <NavDropdown.Item href="/signup">Sign up</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/upload">Upload video</Nav.Link>
          </Nav>
          <Form className="d-flex" onSubmit={handleSearch}>
            <FormControl
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              ref={searchRef}
            />
            <Button variant="outline-success" type="submit">Search</Button>
          </Form>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const styles = {
  navbar: {
    minHeight: "10vh",
  },
  searchbar: {
    
  }
}