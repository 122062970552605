import React, { useState, useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useFirebase } from '../contexts/FirebaseContext';

export default function Comment({comment, username, userID, timestamp}) {
  return (
    <Card style={styles.mainCard}>
        <Card.Body>
            <Link to={"/channel/?id="+userID} style={styles.link}>
            <Card.Title style={styles.user}>{username}</Card.Title>
            </Link>
            <Card.Text>
            {comment}
            </Card.Text>
            <Card.Text style={styles.comment}>
            {new Date(timestamp).toLocaleDateString(
                "en-UK", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}
            )}
            </Card.Text>
        </Card.Body>
    </Card>
  )
}

const styles = {
  link: {
    textDecoration: "none",
  },
  mainCard: {
      width: "100%",
      marginTop: "2vh",
  },
  user: {
    color: "#000000"
  },
  comment: {
    color: "#707071",
  },
}